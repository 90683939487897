<!-- SearchResultsTable.vue -->
<template>
  <div class="tableWrapper">
    <div class="table-header d-flex justify-content-between align-items-center mb-3">
      <div class="d-flex align-items-center">
        <!-- Selection Controls -->
        <div v-if="items.length > 0 && showSelect" class="d-flex align-items-center me-3">
          <b-button variant="primary" size="sm" @click="selectAllRows">Select All</b-button>
          <b-button variant="outline-secondary" size="sm" class="ms-2" @click="clearSelected">Clear Selected</b-button>
        </div>
        
        <!-- Error Alert -->
        <b-alert v-if="error" variant="danger" show class="py-1 px-2 mb-0">
          Error from the server
        </b-alert>
      </div>

      <div class="d-flex align-items-center">
        <!-- Displaying X - Y of Z results -->
        <span v-if="items.length > 0" class="me-3 mr-5 text-muted">
          Displaying 
          {{ ((currentPage - 1) * perPage) + 1 }} 
          - 
          {{ Math.min(currentPage * perPage, items.length) }} 
          of 
          {{ items.length }} results
        </span>

        <!-- Results per page -->
        <label for="results-per-page" class="me-2 mr-2 mb-0">Results per page:</label>
        <b-form-select
          id="results-per-page"
          v-model="perPage"
          :options="[
            { value: 25, text: '25' },
            { value: 50, text: '50' },
            { value: 100, text: '100' },
            { value: 250, text: '250' }
          ]"
          size="sm"
          class="me-3"
          style="width: 80px;"
        ></b-form-select>

        <!-- Pagination -->
        <b-pagination
          v-if="items.length > 0"
          v-model="currentPage"
          :total-rows="items.length"
          :per-page="perPage"
          size="sm"
          class="mb-0"
        ></b-pagination>
      </div>
    </div>

    <div v-if="loading" class="d-flex justify-content-center align-items-center" style="height: 100%;">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <b-table
      no-local-sorting
      small
      striped
      hover
      :items="paginatedData"
      :fields="processedFields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      ref="selectableTable"
      @row-selected="onRowSelected"
      @sort-changed="onSortChanged"
      v-if="items.length > 0 && !loading"
    >
      <template v-slot:[`cell(${field.key})`]="data" v-for="field in fields">
        <div :key="field.key" class="d-flex text-left">
          {{ data.value }}
        </div>
      </template>
      <template v-slot:[`head(${field.key})`]="data" v-for="field in fields">
        <div :key="field.key" class="d-flex text-left">
          <div class="mr-2" v-if="field.helpLink">
            <a :href="field.helpLink" target="_blank" style="color: black">
              <b-icon class="ml-2" :id="field.id" icon="question-circle-fill"></b-icon>
            </a>
          </div>
          {{ data.label }}
        </div>
      </template>
      <template #head(locus)="data">
        <div class="d-flex text-center">
          <span>{{ data.label }}</span>
        </div>
      </template>
      <template #head(protein)="data">
        <div class="d-flex text-center">
          <span>{{ data.label }}</span>
        </div>
      </template>
      <template #head(desc)="data">
        <div class="d-flex align-items-center justify-content-start">
          <span>{{ data.label }}</span>
          <b-button id="popover-target-2" class="ml-2 p-0" variant="link" style="color: inherit">
            <b-icon icon="question-circle" font-scale="1.0"></b-icon>
          </b-button>
          <b-popover target="popover-target-2" triggers="hover" placement="top">
            <template #title>Description</template>
            A brief description of the gene. Usually a brief summary about the gene 
            written by TAIR curators based upon the literature. Summaries are also 
            taken from the GenBank record.
          </b-popover>
        </div>
      </template>
      <template v-slot:cell(selected)="row">
        <b-form-group>
          <input
            type="checkbox"
            v-model="row.item.selected"
            class="tight-checkbox"
            @change="onRowSelected(row.item)"
            aria-label="Select row"
          />
        </b-form-group>
      </template>

      <template #row-details="row">
        <td :colspan="fields.length">
          <div v-html="row.item.html"></div>
        </td>
      </template>

      <template #head(row_num)="data">
        <div class="d-flex justify-content-center" :style="{ width: '50px' }">
          <span class="hashtag-icon">No.</span>
        </div>
      </template>

      <template #cell(row_num)="{ index }">
        <div class="d-flex text-center">
          <span>{{ (currentPage - 1) * perPage + index + 1 }}</span>
        </div>
      </template>

      <template #cell(name)="data">
        <a :href="data.item.url" style="text-align: left; display: block">{{ data.item.name }}</a>
      </template>

      <template
        v-for="(field1, i) in fields.filter((field) => field.cellType === 'name_link')"
        #[`cell(${field1.key})`]="data"
      >
        <div class="d-flex text-left" :key="i">
          <link-wrapper :link="data.value.link" :name="data.value.name" />
        </div>
      </template>

      <template
        v-for="(field4, i) in fields.filter((field) => field.cellType === 'name_list')"
        #[`cell(${field4.key})`]="data"
      >
        <div class="d-flex flex-column text-left" :key="i">
          <div v-for="(l, j) in data.value" :key="j">
            <span>{{ l.name }}</span>
          </div>
        </div>
      </template>

      <template
        v-for="(field2, i) in fields.filter((field) => field.cellType === 'name_links')"
        #[`cell(${field2.key})`]="data"
      >
        <div class="d-flex text-left" :key="i">
          <div v-for="(l, j) in data.value" class="mr-2" :key="j">
            <router-link v-if="l.link" class="locusLink" :to="l.link">
              {{ l.name }}
            </router-link>
            <span v-else>{{ l.name }}</span>
          </div>
        </div>
      </template>

      <template
        v-for="(field3, i) in fields.filter((field) => field.cellType === 'name_links_multi')"
        #[`cell(${field3.key})`]="data"
      >
        <div :key="i" class="d-flex flex-column text-left">
          <div v-for="(l, i) in data.value" class="mr-2" :key="i">
            <router-link v-if="l.link" class="locusLink" :to="l.link">
              {{ l.name }}
            </router-link>
            <a v-else-if="l.externalLink" :href="l.externalLink" target="_blank">{{ l.name }}</a>
            <span v-else>{{ l.name }}</span>
          </div>
        </div>
      </template>

      <template
        v-for="fieldHtml in fields.filter((field) => field.cellType === 'html')"
        #[`cell(${fieldHtml.key})`]="data"
      >
        <div v-for="(l, i) in data.value" :key="i" class="d-flex text-left mr-2">
          <div v-html="l" class="mr-2"></div>
        </div>
      </template>

      <template #cell(description)="data">
        <div class="two-line-ellipsis text-left">
          {{ data.value }}
        </div>
      </template>
      <template #cell(protein_models)="data">
        <div class="d-flex text-left">
          <span
            class="locusLink"
            @click="$emit('cell-clicked', { cellType: 'locus', value: data.value.locusId })"
          >
            {{ data.value.locusName }}
          </span>
          <span v-if="data.value.locusId && data.value.geneId">/</span>
          <span
            class="locusLink"
            @click="$emit('cell-clicked', { cellType: 'gene', value: data.value.geneId })"
          >
            {{ data.value.geneName }}
          </span>
        </div>
      </template>
    </b-table>

    <div class="table-header d-flex justify-content-between align-items-center mb-3">
      <div class="d-flex align-items-center">
        <!-- Selection Controls -->
        <div v-if="items.length > 0 && showSelect" class="d-flex align-items-center me-3">
          <b-button variant="primary" size="sm" @click="selectAllRows">Select All</b-button>
          <b-button variant="outline-secondary" size="sm" class="ms-2" @click="clearSelected">Clear Selected</b-button>
        </div>
        
      </div>

      <div class="d-flex align-items-center">
        <!-- Displaying X - Y of Z results -->
        <span v-if="items.length > 0" class="me-3 mr-5 text-muted">
          Displaying 
          {{ ((currentPage - 1) * perPage) + 1 }} 
          - 
          {{ Math.min(currentPage * perPage, items.length) }} 
          of 
          {{ items.length }} results
        </span>

        <!-- Results per page -->
        <label for="results-per-page" class="me-2 mr-2 mb-0">Results per page:</label>
        <b-form-select
          id="results-per-page"
          v-model="perPage"
          :options="[
            { value: 25, text: '25' },
            { value: 50, text: '50' },
            { value: 100, text: '100' },
            { value: 250, text: '250' }
          ]"
          size="sm"
          class="me-3"
          style="width: 80px;"
        ></b-form-select>

        <!-- Pagination -->
        <b-pagination
          v-if="items.length > 0"
          v-model="currentPage"
          :total-rows="items.length"
          :per-page="perPage"
          size="sm"
          class="mb-0"
        ></b-pagination>
      </div>
    </div>

    <!-- Always Visible Back to Top Button -->
    <b-button
      variant="secondary"
      size="sm"
      class="back-to-top"
      @click="scrollToTop"
    >
      Back to Top
    </b-button>
  </div>
</template>


<script>
import LinkWrapper from "@/components/common/LinkWrapper.vue";
export default {
  components: { LinkWrapper },
  props: {
    items: Array,
    fields: Array,
    loading: Boolean,
    error: Boolean,
    showSelect: {
      type: Boolean,
      default: true,
    },
    perPageProp: {
      type: Number,
      default: 25,
    },
  },
  computed: {
    processedFields() {
      return this.fields.map(field => ({
        ...field,
        sortable: !(
          field.key === 'row_num' ||
          field.key === 'selected' ||
          field.key === 'desc' ||
          field.key === 'tv' ||
          field.key === 'assoc_data' ||
          field.key === 'assoc_data_child' ||
          field.key === 'description' ||
          field.key === 'stock_number' ||
          field.key === 'donor'
        )
      }));
    },
    paginatedData() {
      let sortedData = [...this.items];
      if (this.sortBy) {
        sortedData.sort((a, b) => {
          let aVal = this.getSortValue(a[this.sortBy]);
          let bVal = this.getSortValue(b[this.sortBy]);
          
          // Check if values are empty
          const aEmpty = (aVal === '' || aVal == null);
          const bEmpty = (bVal === '' || bVal == null);

          // If one is empty and the other is not, the empty one goes last
          if (aEmpty && !bEmpty) return 1;
          if (bEmpty && !aEmpty) return -1;

          // If both are empty, consider them equal in terms of ordering
          if (aEmpty && bEmpty) return 0;

          // Normal sorting logic for non-empty values
          if (aVal < bVal) return this.sortDesc ? 1 : -1;
          if (aVal > bVal) return this.sortDesc ? -1 : 1;
          return 0;
        });
      }

      let start = (this.currentPage - 1) * this.perPage;
      let end = start + this.perPage;
      return sortedData.slice(start, end);
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: this.perPageProp,
      detailsShown: [],
      sortBy: null,
      sortDesc: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    onSortChanged({ sortBy, sortDesc }) {
      console.log(`Currently sorting by: ${sortBy}, Descending: ${sortDesc}`);
    },
    getSortValue(value) {
      if (value == null) return '';
      
      if (Array.isArray(value)) {
        return value.map(v => (v && v.name ? v.name.toLowerCase() : '')).join(' ');
      }
      
      if (typeof value === 'object') {
        return (value.name || value.value || '').toLowerCase();
      }
      
      if (typeof value === 'string') {
        return value.toLowerCase();
      }
      
      return value;
    },
    // ... rest of existing methods remain unchanged
    toggleDetails(index) {
      const position = this.detailsShown.indexOf(index);
      if (position >= 0) {
        this.detailsShown.splice(position, 1);
      } else {
        this.detailsShown.push(index);
      }
    },
    onRowSelected(items) {
      this.$emit("row-selected", items);
    },
    selectAllRows() {
      this.items.map((i) => (i.selected = true));
    },
    clearSelected() {
      this.items.map((i) => (i.selected = false));
    },
    getAllSequences() {
      let all_locus = this.items.map((g) => g.locus.name);
      this.$router.push({
        path: "/type_sequences",
        name: "BulkSequenceSearch",
        params: { ids: all_locus.join(",") },
      });
    },
    getCheckedSequences() {
      let all_locus = this.items
        .filter((s) => s.selected)
        .map((g) => g.locus.name);
      this.$router.push({
        path: "/type_sequences",
        name: "BulkSequenceSearch",
        params: { ids: all_locus.join(",") },
      });
    },
  },
  watch: {
    currentPage(newVal) {
      this.$emit("update:currentPage", newVal);
    },
    perPage() {
      this.currentPage = 1;
    },
  },
};
</script>

<style lang="scss">
.tableWrapper {
  overflow: visible; // Changed from hidden to visible
}
.locusLink {
  color: #2a568f;
  cursor: pointer;
  &:hover {
    background: #feffbb;
  }
}
.narrow {
  max-width: 10%;
}
.wide {
  width: 70%;
}
.table-header {
  /* Add a subtle border at the bottom for visual separation */
  border-top: 1px solid #e0e0e0;
  
  /* Add some vertical spacing */
  padding-top: 1rem;

}

.table-header .btn {
  /* Add some spacing around buttons for a cleaner look */
  margin-right: 0.5rem;
}

.table-header .alert {
  /* Keep the alert minimal in this area */
  margin-bottom: 0; 
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.table-header label {
  /* Ensure labels line up nicely with form elements */
  margin-bottom: 0; 
  font-size: 0.9rem;
}

.table-header select {
  /* Ensure the dropdown is not too large */
  max-width: 100px;
}
</style>
